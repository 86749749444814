
import { defineComponent, useFetch, useMeta } from '@nuxtjs/composition-api';
import { usePage, useAlternateLinks } from '@/composables';

export default defineComponent({
  setup() {
    const { alternateLinks } = useAlternateLinks();
    useMeta(() => ({
      link: alternateLinks.value,
    }));

    const { page, fetchPage } = usePage('main');
    useFetch(async () => {
      await fetchPage();
    });

    return {
      page,
    };
  },
  head: {},
});
