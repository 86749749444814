const EXCLUDED_BLOCKS = ['page-info', 'short_description'];

const getBlockContent = (block: any) => {
  if (block.content) {
    return {
      ...block.content,
      type: block.content.type,
      data: block.content.data,
    };
  }
  return {
    type: block.type,
    data: block.content?.data ?? block.data,
  };
};

export default (block: any) => {
  if (block.type === 'textarea') {
    return { type: 'prose-block', data: block.content };
  }

  const normalizedBlock = getBlockContent(block);

  if (EXCLUDED_BLOCKS.includes(normalizedBlock?.type)) return {};

  return normalizedBlock;
};
